@import '~app/mixins';

.lmOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  .lmUpgrade {
    @include primary-border-color();

    pointer-events: none;
    display: none;
    text-align: center;
    background-color: white;
    padding: 10px 0;
    color: #888;
    font-size: 13px;
    font-weight: bold;
    border-radius: 6px;
    border-width: 2px;
    border-style: solid;
    transition: opacity 0.5s linear;
    width: 390px;

    .link,
    .link:hover {
      color: #e64f15;
    }
  }

  &:hover .lmUpgrade {
    pointer-events: all;
    display: block;
    transition: opacity 0.1s linear;
  }
}
